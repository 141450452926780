import { inject } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../services/user.service';

export function SalesUserGuard(state: RouterStateSnapshot): boolean {

  const router = inject(Router);
  const userService = inject(UserService);

  const userRole = userService.user.roles[0];
  const isAdmin = userRole === 'ROLE_ADMIN';
  const isSalesUser = userRole === 'ROLE_ADMIN_SALES';
  const skillQuestionRoutes = ['global-questions', 'skill-question-categories','skill-question-subcategories'];
  const allowSalesUser =
    isSalesUser && state.url.includes('company') && !!userService.user.allowedToCreateLabels ||
    isSalesUser && skillQuestionRoutes.some((skillRoute) => state.url.includes(skillRoute)) && !!userService.user.allowedToManagePredefinedBusinessQuestions;

  if (isAdmin || allowSalesUser) {
    return true;
  }

  router.navigate(['/kpi-statistics']);
  return false;
}
